import { Box } from '@mui/material';

import './Header.scss';

const Header = () => {
    return (
        <Box className="headerWrapper">
            <Box className="logoBrandWrapper">{/* <LogoBrand /> */}</Box>
        </Box>
    );
};

export default Header;
