import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import useStepper from '@components/Stepper/hook';

import './Stepper.scss';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();

    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);

    return (
        <Box className="stepper">
            {/* <LogoBrand /> */}
            <Box className="stepperContent">
                <Box className="stepName">
                    {t('common.step.' + camelStepNames[currStepIndex])}
                </Box>
            </Box>
        </Box>
    );
}
